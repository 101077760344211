*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, system-ui;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roboto Mono, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-3 {
  right: .75rem;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-8 {
  top: 2rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-me-2 {
  margin-inline-end: -.5rem;
}

.-ms-4 {
  margin-inline-start: -1rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-0 {
  min-height: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2 {
  width: .5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-\[1em\] {
  width: 1em;
}

.w-\[80\%\] {
  width: 80%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.min-w-0 {
  min-width: 0;
}

.max-w-\[50vw\] {
  max-width: 50vw;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-top {
  transform-origin: top;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/3 {
  --tw-translate-x: -33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/3 {
  --tw-translate-y: -33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/3 {
  --tw-translate-y: 33.3333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes gyro-lg {
  0% {
    translate: -7%;
    rotate: 0 -1 0 76deg;
  }

  70% {
    translate: 0;
  }

  100% {
    translate: -7%;
    rotate: 0 -1 0 436deg;
  }
}

.animate-gyro-lg {
  animation: 2s infinite gyro-lg;
}

@keyframes gyro-md {
  0% {
    translate: 7% -10%;
    rotate: -1 1 0 76deg;
  }

  70% {
    translate: 0;
  }

  100% {
    translate: 7% -10%;
    rotate: -1 1 0 436deg;
  }
}

.animate-gyro-md {
  animation: 2s infinite gyro-md;
}

@keyframes gyro-sm {
  0% {
    translate: 6% 11%;
    rotate: -1 -1 0 76deg;
  }

  70% {
    translate: 0;
  }

  100% {
    translate: 6% 11%;
    rotate: -1 -1 0 436deg;
  }
}

.animate-gyro-sm {
  animation: 2s infinite gyro-sm;
}

@keyframes page-back {
  from {
    translate: -66.6666%;
  }

  to {
    translate: -33.3333%;
  }
}

.animate-page-back {
  animation: .3s ease-in-out forwards page-back;
}

@keyframes page-forward {
  from {
  }

  to {
    translate: -33.3333%;
  }
}

.animate-page-forward {
  animation: .3s ease-in-out forwards page-forward;
}

@keyframes pop-in {
  from {
    opacity: 0;
    transform: scale(.95);
  }

  to {
  }
}

.animate-pop-in {
  animation: .4s cubic-bezier(.34, 1.56, .64, 1) forwards pop-in;
}

@keyframes pop-out {
  from {
  }

  to {
    opacity: 0;
    transform: scale(.95);
  }
}

.animate-pop-out {
  animation: .1s cubic-bezier(.25, 1, .5, 1) forwards pop-out;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes scale-in-x {
  from {
    scale: 0 1;
  }

  to {
  }
}

.animate-scale-in-x {
  animation: .5s cubic-bezier(.33, 1, .68, 1) forwards scale-in-x;
}

.cursor-grab {
  cursor: grab;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[max-content\,12rem\,max-content\] {
  grid-template-columns: max-content 12rem max-content;
}

.grid-cols-\[max-content\,auto\] {
  grid-template-columns: max-content auto;
}

.grid-cols-\[repeat\(auto-fill\,minmax\(12rem\,1fr\)\)\] {
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-px {
  gap: 1px;
}

.gap-y-4 {
  row-gap: 1rem;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-gray-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: hsl(227 24% 80% / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-clip {
  overflow: clip;
}

.\!overflow-x-clip {
  overflow-x: clip !important;
}

.\!overflow-y-clip {
  overflow-y: clip !important;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-e {
  border-inline-end-width: 1px;
}

.border-e-2 {
  border-inline-end-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-s-2 {
  border-inline-start-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-current {
  border-color: currentColor;
}

.border-gray-42 {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
}

.border-gray-67 {
  --tw-border-opacity: 1;
  border-color: hsl(228 3% 67% / var(--tw-border-opacity));
}

.border-gray-80 {
  --tw-border-opacity: 1;
  border-color: hsl(227 24% 80% / var(--tw-border-opacity));
}

.border-gray-86 {
  --tw-border-opacity: 1;
  border-color: hsl(218 11% 86% / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-e-primary {
  --tw-border-opacity: 1;
  border-inline-end-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.border-e-transparent {
  border-inline-end-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-s-primary {
  --tw-border-opacity: 1;
  border-inline-start-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-gray-31 {
  --tw-bg-opacity: 1;
  background-color: hsl(228 19% 31% / var(--tw-bg-opacity));
}

.bg-gray-48 {
  --tw-bg-opacity: 1;
  background-color: hsl(218 11% 48% / var(--tw-bg-opacity));
}

.bg-gray-67 {
  --tw-bg-opacity: 1;
  background-color: hsl(228 3% 67% / var(--tw-bg-opacity));
}

.bg-gray-80 {
  --tw-bg-opacity: 1;
  background-color: hsl(227 24% 80% / var(--tw-bg-opacity));
}

.bg-gray-86 {
  --tw-bg-opacity: 1;
  background-color: hsl(218 11% 86% / var(--tw-bg-opacity));
}

.bg-gray-94 {
  --tw-bg-opacity: 1;
  background-color: hsl(220 27% 94% / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-purple-44 {
  --tw-gradient-from: #944c93 var(--tw-gradient-from-position);
  --tw-gradient-to: #944c9300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-purple-36 {
  --tw-gradient-to: #4e2790 var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.fill-white {
  fill: #fff;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[0\.1875rem\] {
  padding-left: .1875rem;
  padding-right: .1875rem;
}

.px-\[15\%\] {
  padding-left: 15%;
  padding-right: 15%;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-3 {
  padding-inline-end: .75rem;
}

.pe-3\.5 {
  padding-inline-end: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.ps-12 {
  padding-inline-start: 3rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-20 {
  padding-inline-start: 5rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.ps-4 {
  padding-inline-start: 1rem;
}

.ps-5 {
  padding-inline-start: 1.25rem;
}

.ps-6 {
  padding-inline-start: 1.5rem;
}

.ps-7 {
  padding-inline-start: 1.75rem;
}

.ps-8 {
  padding-inline-start: 2rem;
}

.ps-\[0\.4375rem\] {
  padding-inline-start: .4375rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.align-\[-0\.125em\] {
  vertical-align: -.125em;
}

.font-mono {
  font-family: Roboto Mono, monospace;
}

.font-montserrat {
  font-family: Montserrat, Roboto, system-ui;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-gray-24 {
  --tw-text-opacity: 1;
  color: hsl(230 19% 24% / var(--tw-text-opacity));
}

.text-gray-27 {
  --tw-text-opacity: 1;
  color: hsl(227 23% 27% / var(--tw-text-opacity));
}

.text-gray-31 {
  --tw-text-opacity: 1;
  color: hsl(228 19% 31% / var(--tw-text-opacity));
}

.text-gray-42 {
  --tw-text-opacity: 1;
  color: hsl(228 11% 42% / var(--tw-text-opacity));
}

.text-gray-80 {
  --tw-text-opacity: 1;
  color: hsl(227 24% 80% / var(--tw-text-opacity));
}

.text-green-48 {
  --tw-text-opacity: 1;
  color: hsl(126 38% 48% / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: hsl(352 68% 50% / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-57 {
  --tw-text-opacity: 1;
  color: hsl(42 91% 57% / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.accent-primary {
  accent-color: hsl(var(--primary));
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-1 {
  outline-width: 1px;
}

.outline-2 {
  outline-width: 2px;
}

.-outline-offset-1 {
  outline-offset: -1px;
}

.outline-blue-62 {
  outline-color: #408efc;
}

.outline-red-50 {
  outline-color: #d62940;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[scale\] {
  transition-property: scale;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-200 {
  transition-delay: .2s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.bg-shade {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

:is(.dark .bg-shade) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

input.revveon-file-input::file-selector-button {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: hsl(218 11% 86% / var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
  background-color: #0000;
  border-radius: .125rem;
  gap: .5rem;
  margin-right: .375rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

input.revveon-file-input::file-selector-button:hover {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

:is(.dark input.revveon-file-input)::file-selector-button:hover {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

input.revveon-file-input::file-selector-button:active {
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark input.revveon-file-input)::file-selector-button {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
}

input.revveon-file-input:first-line {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: hsl(228 11% 42% / var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375;
}

:is(.dark input.revveon-file-input):first-line {
  --tw-text-opacity: 1;
  color: hsl(227 24% 80% / var(--tw-text-opacity));
}

html {
  --primary: 301 32% 44%;
}

html.dark {
  --primary: 301 33% 68%;
}

[id] {
  scroll-margin-top: 2rem;
}

.dark input {
  color-scheme: dark;
}

div.iti {
  isolation: isolate;
  flex-grow: 1;
  display: flex;
}

::-ms-reveal {
  display: none;
}

.iti__flag {
  background-image: url("flags.7a09455a.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("flags@2x.1fa1c028.png");
  }
}

.iti .iti__country-list {
  --tw-border-opacity: 1;
  border-color: hsl(218 11% 86% / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .125rem;
}

:is(.dark .iti .iti__country-list) {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(228 19% 31% / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.iti .iti__dial-code {
  --tw-text-opacity: 1;
  color: hsl(228 11% 42% / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

:is(.dark .iti .iti__dial-code) {
  --tw-text-opacity: 1;
  color: hsl(218 11% 86% / var(--tw-text-opacity));
}

.iti .iti__divider {
  --tw-border-opacity: 1;
  border-color: hsl(218 11% 86% / var(--tw-border-opacity));
}

:is(.dark .iti .iti__divider) {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
}

.iti .iti__country.iti__highlight {
  --tw-bg-opacity: 1;
  background-color: hsl(220 27% 94% / var(--tw-bg-opacity));
}

:is(.dark .iti .iti__country.iti__highlight) {
  --tw-bg-opacity: 1;
  background-color: hsl(227 23% 27% / var(--tw-bg-opacity));
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.first\:rounded-s-sm:first-child {
  border-start-start-radius: .125rem;
  border-end-start-radius: .125rem;
}

.last\:rounded-e-sm:last-child {
  border-start-end-radius: .125rem;
  border-end-end-radius: .125rem;
}

.focus-within\:outline:focus-within {
  outline-style: solid;
}

.focus-within\:outline-2:focus-within {
  outline-width: 2px;
}

.focus-within\:-outline-offset-1:focus-within {
  outline-offset: -1px;
}

.focus-within\:outline-blue-62:focus-within {
  outline-color: #408efc;
}

.hover\:w-56:hover {
  width: 14rem;
}

.hover\:scale-\[101\%\]:hover {
  --tw-scale-x: 101%;
  --tw-scale-y: 101%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-gray-86:hover {
  --tw-border-opacity: 1;
  border-color: hsl(218 11% 86% / var(--tw-border-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:decoration-2:hover {
  text-decoration-thickness: 2px;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:outline:hover {
  outline-style: solid;
}

.hover\:outline-2:hover {
  outline-width: 2px;
}

.hover\:outline-gray-80:hover {
  outline-color: #c0c5d8;
}

.hover\:brightness-95:hover {
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:bg-shade:hover {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

:is(.dark .hover\:bg-shade:hover) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

.hover\:\[scale\:1\.0_1\.66\]:hover {
  scale: 1 1.66;
}

.hover\:\[scale\:1\.66_1\.0\]:hover {
  scale: 1.66 1;
}

.focus\:outline-none:focus, .focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:-outline-offset-1:focus-visible {
  outline-offset: -1px;
}

.focus-visible\:outline-blue-62:focus-visible {
  outline-color: #408efc;
}

.active\:shadow-none:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:brightness-105:active {
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:no-underline:disabled {
  text-decoration-line: none;
}

.disabled\:opacity-60:disabled {
  opacity: .6;
}

.disabled\:opacity-70:disabled {
  opacity: .7;
}

.disabled\:grayscale:disabled {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:bg-shade {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

:is(.dark .group:hover .group-hover\:bg-shade) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

.group\/radio:focus-visible .group-focus-visible\/radio\:outline {
  outline-style: solid;
}

.group\/radio:focus-visible .group-focus-visible\/radio\:outline-2 {
  outline-width: 2px;
}

.group\/radio:focus-visible .group-focus-visible\/radio\:-outline-offset-1 {
  outline-offset: -1px;
}

.group\/radio:focus-visible .group-focus-visible\/radio\:outline-blue-62 {
  outline-color: #408efc;
}

.aria-checked\:border-b-primary[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.aria-checked\:bg-opacity-5[aria-checked="true"] {
  --tw-bg-opacity: .05;
}

.aria-checked\:text-primary[aria-checked="true"] {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.aria-\[current\=page\]\:border-primary[aria-current="page"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.aria-\[current\=page\]\:border-r-primary[aria-current="page"] {
  --tw-border-opacity: 1;
  border-right-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.aria-\[current\=page\]\:text-primary[aria-current="page"] {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

.aria-\[current\=page\]\:bg-shade[aria-current="page"] {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

:is(.dark .aria-\[current\=page\]\:bg-shade[aria-current="page"]) {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
}

.group\/switch[aria-checked="true"] .group-aria-checked\/switch\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:translate-x-full[data-state="checked"] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\*\=open\]\:animate-pop-in[data-state*="open"] {
  animation: .4s cubic-bezier(.34, 1.56, .64, 1) forwards pop-in;
}

@keyframes slide-left {
  from {
    translate: 100%;
  }

  to {
  }
}

.data-\[state\*\=open\]\:animate-slide-left[data-state*="open"] {
  animation: .25s cubic-bezier(.25, 1, .5, 1) forwards slide-left;
}

@keyframes toast-in {
  from {
    opacity: 0;
    origin: bottom;
    transform: scale(.7);
  }

  to {
    origin: bottom;
  }
}

.data-\[state\*\=open\]\:animate-toast-in[data-state*="open"] {
  animation: .5s cubic-bezier(.34, 1.56, .64, 1) forwards toast-in;
}

.data-\[state\=closed\]\:animate-pop-out[data-state="closed"] {
  animation: .1s cubic-bezier(.25, 1, .5, 1) forwards pop-out;
}

@keyframes slide-right {
  from {
  }

  to {
    translate: 100%;
  }
}

.data-\[state\=closed\]\:animate-slide-right[data-state="closed"] {
  animation: .25s cubic-bezier(.25, 1, .5, 1) forwards slide-right;
}

@keyframes toast-out {
  from {
    origin: top;
  }

  to {
    opacity: 0;
    origin: top;
    transform: scale(.5);
  }
}

.data-\[state\=closed\]\:animate-toast-out[data-state="closed"] {
  animation: .4s cubic-bezier(.25, 1, .5, 1) forwards toast-out;
}

.data-\[state\=open\]\:animate-slide-left[data-state="open"] {
  animation: .25s cubic-bezier(.25, 1, .5, 1) forwards slide-left;
}

.data-\[invalid\]\:border-red-50[data-invalid] {
  --tw-border-opacity: 1;
  border-color: hsl(352 68% 50% / var(--tw-border-opacity));
}

.data-\[state\=visible\]\:opacity-100[data-state="visible"] {
  opacity: 1;
}

.group\/trigger[data-state="open"] .group-data-\[state\=open\]\/trigger\:rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/radio[data-state="checked"] .group-data-\[state\=checked\]\/radio\:border-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--primary) / var(--tw-border-opacity));
}

.peer[data-invalid] ~ .peer-data-\[invalid\]\:outline {
  outline-style: solid;
}

.prose-h1\:text-4xl :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.prose-h1\:font-thin :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 100;
}

.prose-h2\:text-sm :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .875rem;
  line-height: 1.25rem;
}

.prose-h2\:font-bold :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 700;
}

.prose-h2\:uppercase :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-transform: uppercase;
}

.prose-h2\:tracking-wide :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  letter-spacing: .025em;
}

.prose-h3\:text-base :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 1rem;
  line-height: 1.5rem;
}

.prose-h3\:font-normal :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 400;
}

.prose-h3\:underline :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-decoration-line: underline;
}

.prose-a\:font-normal :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 400;
}

:is(.dark .dark\:divide-gray-42) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-divide-opacity));
}

:is(.dark .dark\:border-gray-31) {
  --tw-border-opacity: 1;
  border-color: hsl(228 19% 31% / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-42) {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-80) {
  --tw-border-opacity: 1;
  border-color: hsl(227 24% 80% / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-gray-12) {
  --tw-bg-opacity: 1;
  background-color: hsl(230 19% 12% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-24) {
  --tw-bg-opacity: 1;
  background-color: hsl(230 19% 24% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-27) {
  --tw-bg-opacity: 1;
  background-color: hsl(227 23% 27% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-31) {
  --tw-bg-opacity: 1;
  background-color: hsl(228 19% 31% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-42) {
  --tw-bg-opacity: 1;
  background-color: hsl(228 11% 42% / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-48) {
  --tw-bg-opacity: 1;
  background-color: hsl(218 11% 48% / var(--tw-bg-opacity));
}

:is(.dark .dark\:from-purple-55) {
  --tw-gradient-from: #bd52c7 var(--tw-gradient-from-position);
  --tw-gradient-to: #bd52c700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

:is(.dark .dark\:to-white) {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

:is(.dark .dark\:fill-gray-42) {
  fill: #5f6477;
}

:is(.dark .dark\:text-gray-16) {
  --tw-text-opacity: 1;
  color: hsl(233 20% 16% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-24) {
  --tw-text-opacity: 1;
  color: hsl(230 19% 24% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-42) {
  --tw-text-opacity: 1;
  color: hsl(228 11% 42% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-80) {
  --tw-text-opacity: 1;
  color: hsl(227 24% 80% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-94) {
  --tw-text-opacity: 1;
  color: hsl(220 27% 94% / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-yellow-57) {
  --tw-text-opacity: 1;
  color: hsl(42 91% 57% / var(--tw-text-opacity));
}

:is(.dark .dark\:outline-yellow-57) {
  outline-color: #f5b92e;
}

:is(.dark .dark\:hover\:border-gray-42:hover) {
  --tw-border-opacity: 1;
  border-color: hsl(228 11% 42% / var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:bg-gray-31:hover) {
  --tw-bg-opacity: 1;
  background-color: hsl(228 19% 31% / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-primary:hover) {
  --tw-text-opacity: 1;
  color: hsl(var(--primary) / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:outline-gray-42:hover) {
  outline-color: #5f6477;
}

:is(.dark .dark\:aria-checked\:border-b-primary[aria-checked="true"]) {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--primary) / var(--tw-border-opacity));
}

:is(.dark .group\/switch[aria-checked="true"] .dark\:group-aria-checked\/switch\:bg-primary) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--primary) / var(--tw-bg-opacity));
}

:is(.dark .dark\:data-\[invalid\]\:border-yellow-57[data-invalid]) {
  --tw-border-opacity: 1;
  border-color: hsl(42 91% 57% / var(--tw-border-opacity));
}

.rtl\:-scale-x-100:where([dir="rtl"], [dir="rtl"] *) {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\:not\(\:first-child\)\]\:-ml-\[1px\]:not(:first-child) {
  margin-left: -1px;
}

.\[\&\>\*\]\:pointer-events-auto > * {
  pointer-events: auto;
}

.\[\&\>\*\]\:grow > * {
  flex-grow: 1;
}

.\[\&\>\*\]\:self-start > * {
  align-self: flex-start;
}

.\[\&\>\*\]\:self-stretch > * {
  align-self: stretch;
}

.\[\&\>div\]\:\!contents > div {
  display: contents !important;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-cyrillic-ext-100-normal.5126aa9d.woff2") format("woff2"), url("roboto-cyrillic-ext-100-normal.d55eba84.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-cyrillic-100-normal.17f29547.woff2") format("woff2"), url("roboto-cyrillic-100-normal.607fecc5.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-greek-ext-100-normal.baabac11.woff2") format("woff2"), url("roboto-greek-ext-100-normal.33586698.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-greek-100-normal.6842995a.woff2") format("woff2"), url("roboto-greek-100-normal.3390f6ef.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-vietnamese-100-normal.7543424c.woff2") format("woff2"), url("roboto-vietnamese-100-normal.71634b02.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-latin-ext-100-normal.6c0ff12b.woff2") format("woff2"), url("roboto-latin-ext-100-normal.29dcda21.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-latin-100-normal.b393c3f0.woff2") format("woff2"), url("roboto-latin-100-normal.eb21b6a1.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.8895cd13.woff2") format("woff2"), url("roboto-cyrillic-ext-300-normal.6e6800bb.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.303b9afa.woff2") format("woff2"), url("roboto-cyrillic-300-normal.c2935f14.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.73ef051e.woff2") format("woff2"), url("roboto-greek-ext-300-normal.e6d63256.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.962b7627.woff2") format("woff2"), url("roboto-greek-300-normal.9def6de0.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.6fb53ea7.woff2") format("woff2"), url("roboto-vietnamese-300-normal.7a36c4ce.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.51d7b0c4.woff2") format("woff2"), url("roboto-latin-ext-300-normal.9ee5529a.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-latin-300-normal.20b3ab25.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-cyrillic-ext-400-normal.a08b2a3e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-cyrillic-400-normal.a5a342c2.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-greek-ext-400-normal.e526b0c1.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-greek-400-normal.8ad61867.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-vietnamese-400-normal.80131064.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-latin-ext-400-normal.7013a0e5.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-latin-400-normal.0a2ad554.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-italic.cb904397.woff2") format("woff2"), url("roboto-cyrillic-ext-400-italic.600186ff.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-italic.bb5bdaa1.woff2") format("woff2"), url("roboto-cyrillic-400-italic.25a78280.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-italic.cbc8a386.woff2") format("woff2"), url("roboto-greek-ext-400-italic.6f138c6f.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-italic.906717f5.woff2") format("woff2"), url("roboto-greek-400-italic.525442e1.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-italic.66811a7d.woff2") format("woff2"), url("roboto-vietnamese-400-italic.d4b7bd91.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-italic.cd9e98cd.woff2") format("woff2"), url("roboto-latin-ext-400-italic.99e74249.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-italic.b9b4832d.woff2") format("woff2"), url("roboto-latin-400-italic.a2177ac5.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-cyrillic-ext-500-normal.4cacad2c.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-cyrillic-500-normal.9867a510.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-greek-ext-500-normal.6ed21af2.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-greek-500-normal.1ddb8d95.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-vietnamese-500-normal.dbc08370.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-latin-ext-500-normal.7f0ca47d.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-latin-500-normal.e1ea9bdc.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.0921acac.woff2") format("woff2"), url("roboto-cyrillic-ext-700-normal.43eb0c1d.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.96126479.woff2") format("woff2"), url("roboto-cyrillic-700-normal.391c23c0.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.00d528bb.woff2") format("woff2"), url("roboto-greek-ext-700-normal.f2982eb0.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.bc3ad99c.woff2") format("woff2"), url("roboto-greek-700-normal.463ab9a3.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.a1447a12.woff2") format("woff2"), url("roboto-vietnamese-700-normal.3a47730c.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.372a1858.woff2") format("woff2"), url("roboto-latin-ext-700-normal.de7451f0.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-latin-700-normal.4b7de361.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-cyrillic-ext-900-normal.e75b2f25.woff2") format("woff2"), url("roboto-cyrillic-ext-900-normal.2857f284.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-cyrillic-900-normal.1d45ba50.woff2") format("woff2"), url("roboto-cyrillic-900-normal.6c633dc8.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-greek-ext-900-normal.44c3b434.woff2") format("woff2"), url("roboto-greek-ext-900-normal.9853240d.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-greek-900-normal.0398fdd0.woff2") format("woff2"), url("roboto-greek-900-normal.5191e405.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-vietnamese-900-normal.918a3365.woff2") format("woff2"), url("roboto-vietnamese-900-normal.7a042b9e.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-latin-ext-900-normal.eb20e8f2.woff2") format("woff2"), url("roboto-latin-ext-900-normal.efa1afea.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-latin-900-normal.5750b1bb.woff2") format("woff2"), url("roboto-latin-900-normal.326d373b.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.098dea10.woff2") format("woff2"), url("roboto-mono-cyrillic-ext-400-normal.7565b9b8.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.65af37c4.woff2") format("woff2"), url("roboto-mono-cyrillic-400-normal.a894837a.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.0113115d.woff2") format("woff2"), url("roboto-mono-greek-400-normal.2d7ad0e9.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.a8d59f49.woff2") format("woff2"), url("roboto-mono-vietnamese-400-normal.76da6e49.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.3af510ff.woff2") format("woff2"), url("roboto-mono-latin-ext-400-normal.883f9e85.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.0205e7bf.woff2") format("woff2"), url("roboto-mono-latin-400-normal.b8abbb74.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-ext-700-normal.71a0f931.woff2") format("woff2"), url("montserrat-cyrillic-ext-700-normal.2ddc8d45.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-cyrillic-700-normal.1d14864d.woff2") format("woff2"), url("montserrat-cyrillic-700-normal.fd0dce65.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-vietnamese-700-normal.f9bc0cca.woff2") format("woff2"), url("montserrat-vietnamese-700-normal.9cbeb9bf.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-ext-700-normal.f37cf23d.woff2") format("woff2"), url("montserrat-latin-ext-700-normal.10b6d985.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.7f5cc6e4.woff2") format("woff2"), url("montserrat-latin-700-normal.71afe628.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root, :host {
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em / 1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em / 1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-sharp-thin: normal 100 1em / 1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  box-sizing: content-box;
  overflow: visible;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  vertical-align: -.125em;
  height: 1em;
  overflow: visible;
}

.svg-inline--fa.fa-2xs {
  vertical-align: .1em;
}

.svg-inline--fa.fa-xs {
  vertical-align: 0;
}

.svg-inline--fa.fa-sm {
  vertical-align: -.07143em;
}

.svg-inline--fa.fa-lg {
  vertical-align: -.2em;
}

.svg-inline--fa.fa-xl {
  vertical-align: -.25em;
}

.svg-inline--fa.fa-2xl {
  vertical-align: -.3125em;
}

.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, .3em);
  width: auto;
}

.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, .3em);
  width: auto;
}

.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: .25em;
}

.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em);
}

.fa-layers svg.svg-inline--fa {
  margin: auto;
  position: absolute;
  inset: 0;
}

.fa-layers-text, .fa-layers-counter {
  text-align: center;
  display: inline-block;
  position: absolute;
}

.fa-layers {
  text-align: center;
  vertical-align: -.125em;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.fa-layers svg.svg-inline--fa {
  transform-origin: center;
}

.fa-layers-text {
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  padding: var(--fa-counter-padding, .25em .5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, .25));
  transform: scale(var(--fa-counter-scale, .25));
  transform-origin: 100% 0;
  overflow: hidden;
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 100% 100%;
  top: auto;
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 0 100%;
  top: auto;
  right: auto;
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 100% 0;
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  transform-origin: 0 0;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  z-index: var(--fa-stack-z-index, auto);
  margin: auto;
  position: absolute;
  inset: 0;
}

.svg-inline--fa.fa-stack-1x {
  width: 1.25em;
  height: 1em;
}

.svg-inline--fa.fa-stack-2x {
  width: 2.5em;
  height: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, .4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, .4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary, .svg-inline--fa mask .fa-secondary {
  fill: #000;
}

.fad.fa-inverse, .fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/*# sourceMappingURL=index.efd1b039.css.map */
