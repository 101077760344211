@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --primary: 301 32% 44%;
}
html.dark {
  --primary: 301 33% 68%;
}

@layer utilities {
  .bg-shade {
    @apply bg-black bg-opacity-5 dark:bg-white dark:bg-opacity-5;
  }
  input.revveon-file-input::file-selector-button {
    @apply hover:bg-shade mr-1.5 cursor-pointer gap-2 rounded-sm border border-solid border-gray-86 bg-transparent px-4 py-2 text-center text-base text-primary active:brightness-105 dark:border-gray-42;
  }
  input.revveon-file-input::first-line {
    @apply text-sm font-normal leading-snug tracking-wide text-gray-42 dark:text-gray-80;
  }
}

[id] {
  scroll-margin-top: 2rem; /* a little breathing room when you navigate to an element via #anchor */
}

.dark input {
  color-scheme: dark;
}

/* intl-tel-input */
div.iti {
  isolation: isolate;
  display: flex;
  flex-grow: 1;
}

::-ms-reveal {
  display: none;
}

.iti__flag {
  background-image: url("../node_modules/intl-tel-input/build/img/flags.png");
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../node_modules/intl-tel-input/build/img/flags@2x.png");
  }
}
.iti .iti__country-list {
  @apply rounded-sm  border-gray-86  shadow-none dark:border-gray-42 dark:bg-gray-31 dark:text-white;
}
.iti .iti__dial-code {
  @apply text-sm text-gray-42 dark:text-gray-86;
}
.iti .iti__divider {
  @apply border-gray-86 dark:border-gray-42;
}
.iti .iti__country.iti__highlight {
  @apply bg-gray-94 dark:bg-gray-27;
}
